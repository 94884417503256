.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #3e79f7;
}

.gap-1 {
  gap: 0.5rem;
}

.gap-2 {
  gap: 1rem;
}

.type-list * {
  max-width: none !important;
}

.line-height-0 * {
  line-height: unset !important;
}

.max-width-25 {
  max-width: 25rem;
}

.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  vertical-align: unset;
}

.filters-list {
  flex-direction: column !important;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.filters-list .ant-form-item {
  width: clamp(10rem, 100%, 100%);
  margin: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.videos-outer .ant-card-bordered, .api-container table {
  width: 740px !important;
}

@media screen and (min-width: 768px) {
  .filters-list {
    gap: 1rem;
    flex-direction: row !important;
  }

  .filters-list .ant-form-item {
    width: clamp(10rem, 100%, 15rem);
  }
}

.phone-ant-input {
  display: flex;
  align-items: center;
}

.phone-ant-input .PhoneInputInput {
  flex: 1;
  box-sizing: border-box;
  margin: 0px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0px;
  padding: 8.5px 11px;
  color: rgb(69, 85, 96);
  font-size: 14px;
  line-height: 1.5;
  background-color: rgb(255, 255, 255);
  background-image: none;
  border: 1px solid rgb(230, 235, 241);
  border-radius: 0.625rem;
  transition: all 0.3s ease 0s;
}

.ellipsis-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Limit to 2 lines */
  -webkit-box-orient: vertical;
}

.custom-autocomplete input.ant-input:focus {
  border-color: red !important;
  /* Set the desired border color when focused */
  box-shadow: none;
  /* Remove any focus box-shadow if present */
}

.react-google-autocomplete input:focus {
  outline: none !important;
  /* Remove the focus outline */
  border: 1px solid #e6ebf1 !important;
  /* Set the desired border color when focused */
}

.day-div-selected {
  background-color: red;
}

.day-div {
  background-color: white;
}

.transaction-modal .ant-col-8 {
  display: block;
  flex: 0 0 100% !important;
  max-width: 100% !important;
  text-align: left;
}

/* -------react calender-------- */
.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #FF6666;
  /* border: 1px solid #a0a096; */
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  border-radius: 8px;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
  background-color: #FF6666;
  border-radius: 8px 8px 0 0;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  color: #fff;
  font-weight: 700;
}

.react-calendar__navigation button:disabled {
  background-color: #cc5151;
  /* background-color: #f0f0f0; */
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #ffa3a3;
  /* background-color: #e6e6e6; */
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 0.833em;
  border-radius: 8px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
  color: #ababab;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
  color: #cdcdcd;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #ffe0e0;
  /* background-color: #e6e6e6; */
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #ff6666;
  /* background: #006edc; */
  color: white;
}

.react-calendar__tile--active p {
  color: white !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #ff6666;
  /* background: #1087ff; */
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

/* -------react calender end-------- */

.react-calendar {
  width: 506.328px !important;
}

.addOnsTitle {}

.transaction-modal .ant-col-8 {
  display: block;
  flex: 0 0 100% !important;
  max-width: 100% !important;
  text-align: left;
}

.cart-customer .ant-row, .ant-row::after, .ant-row::before {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

.cart-customer .ant-col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
  width: 100%;
}

.cart-customer .ant-col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
  width: 100%;
  text-align: left !important;
}

/* calendar page */

.fc-h-event .fc-event-title-container {
  cursor: pointer;
  background: red;
  font-weight: 500;
}

.fc-theme-standard td {
  height: 150px;
}

.button-cls {
  display: flex;
  align-items: end !important;
  width: 100% !important;
  margin-left: auto !important;
}

.warn-color {
  color: #faad14;
}

.information-input .ant-col-8  {
  max-width: 100% !important ;
}

.video-mandatory{
  display: inline-block;
  margin-right: 4px;
  color: #ff6b72;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.sun-editor .se-wrapper .se-wrapper-wysiwyg {
  display: block;
  height: 460px !important;
}

.reactEasyCrop_Container {
  width: 700px !important;
  height: 500px !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  user-select: none;
  touch-action: none;
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listing-add .anticon-delete{
  margin-top: -7px !important;
}

.logo-image{
  width: 40px;
  margin-bottom: 10px;
}

.tax-details .ant-col-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 100% !important;
}
